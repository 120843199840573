'use client';

import Link from 'next/link';
import Button from 'src/components/Button';
import NavFooterLayout from 'src/components/Layouts/NavFooterLayout';
import Text from 'src/components/Text/Text';
import { DRIFT_DISCORD_URL } from 'src/constants/constants';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';

const PREVIEW_LINK_SUFFIX = 'drift-web.vercel.app';

const ErrorPage = ({ error }: { error: any }) => {
	const domain = window.location.hostname;
	const isPreviewLink = domain.includes(PREVIEW_LINK_SUFFIX);

	return (
		<NavFooterLayout currentPage="error">
			<div className="flex items-center justify-center h-screen px-6 bg-main-bg">
				<div className="flex flex-col items-center md:flex-row-reverse justify-center max-w-[458px] md:max-w-none max-h-full pb-4 md:gap-[68px]">
					<div className="sm:h-[300px] sm:w-[300px] md:h-[458px] md:w-[458px]">
						<img
							src="/assets/images/drift-error.webp"
							className={twMerge('object-contain object-left')}
						/>
					</div>

					<div className="flex flex-col mt-8 sm:mt-0 md:max-w-[408px]">
						<Text.H1 className="text-center md:text-left text-gradient-1 text-[32px] sm:text-[42px] font-extrabold leading-10">
							You&apos;ve drifted a bit too far
						</Text.H1>

						<div className="flex flex-col gap-6 text-center max-w-[458px] mt-6 sm:mt-8">
							<Text.BODY1 className="text-center text-text-label md:text-left">
								Something&apos;s gone wrong with the action you&apos;ve
								performed. Go back to the Homepage and try again. If this
								problem persists, please reach out on{' '}
								<span
									className="underline cursor-pointer"
									onClick={() => UI_UTILS.openUrl(DRIFT_DISCORD_URL)}
								>
									Discord
								</span>{' '}
								or{' '}
								<span
									className="underline cursor-pointer"
									onClick={() => UI_UTILS.openUrl('')}
								>
									Email
								</span>
								.
							</Text.BODY1>

							<div className="flex items-center w-full gap-4">
								<Link href="https://app.drift.trade" className="w-full">
									<Button.Secondary
										className="text-[13px] flex-1 w-full"
										size="MEDIUM"
										highlight
									>
										Go to Homepage
									</Button.Secondary>
								</Link>
								<Link href="https://backup.drift.trade" className="w-full">
									<Button.Secondary
										className="text-[13px] flex-1 w-full"
										size="MEDIUM"
									>
										Visit backup site
									</Button.Secondary>
								</Link>
							</div>
						</div>
					</div>
				</div>

				{/** Used to debug on mobile phones where access to console is not available */}
				{isPreviewLink && !!error && (
					<div className="absolute mb-4 mr-4 overflow-auto text-xs sm:hidden right-2 bottom-2 left-2 top-10 text-text-default bg-red-50">
						{JSON.stringify(error)}
					</div>
				)}
			</div>
		</NavFooterLayout>
	);
};

export default ErrorPage;
